import React from "react";

import "./home.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
// import fundois from "../../assets/fundois.png";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "far" && key !== "prefix")
  .map((icon: any) => {
    return (Icons as any)[icon];
  });

library.add(...iconList);

class Home extends React.Component {
  render() {
    return (
      <>
        <div className="home">
          <div className="home-fundo">
            <div className="background-opacity"></div>
            <div className="text-over" style={{ width: "100%" }}>
              <h1 className="home-titulo">
                Em busca de um Sistema de
                <br />
                Gestão Customizado?
              </h1>
              <br />
              <div style={{ position: "relative", lineHeight: "30px" }}>
                <p className="home-subtitulo">Impulsione o seu negócio:</p>
                <p className="home-frase">
                  Nosso ERP contempla módulos que automatizam os processos administrativos da sua empresa, permitindo mais eficiência e
                  produtividade.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="home-cards">
          <div className="shadow-cards">
            <FontAwesomeIcon icon="hand-pointer" size="2x" color="#2A5699" />
            <p className="title-icone">Fácil de Usar</p>
          </div>

          <div className="shadow-cards">
            <FontAwesomeIcon icon="paper-plane" size="2x" color="#2A5699" />
            <p className="title-icone">Automatize Tarefas Repetitivas</p>
          </div>

          <div className="shadow-cards">
            <FontAwesomeIcon icon="funnel-dollar" size="2x" color="#2A5699" />
            <p className="title-icone">Mantenha o Controle Comercial</p>
          </div>

          <div className="shadow-cards">
            <FontAwesomeIcon icon="list-alt" size="2x" color="#2A5699" />
            <p className="title-icone">Centralize as Informações</p>
          </div>

          <div className="shadow-cards">
            <FontAwesomeIcon icon="clock" size="2x" color="#2A5699" />
            <p className="title-icone">Ganhe Velocidade no Envio das Propostas</p>
          </div>
        </div>

        <div className="desc-sistema">
          <div className="desc-texto">
            <h1 className="titulo-desc">Tudo o que você precisa a seu alcance, tenha uma visão completa do seu negócio.</h1>

            <div className="desc-is">
              O IS Gestão, tem a edição certa para te ajudar a vender mais e ter uma uma organização consolidada para conquistar clientes.
              Faça a gestão de sua empresa ficar mais simples, rápida e eficiente. Você vai ganhar tempo para focar no seu negócio.
            </div>
          </div>
          <div className="img-note"></div>
        </div>
      </>
    );
  }
}

export default Home;
