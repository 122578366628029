import React from "react";

import "./rodape.css";

// import logo from '../../assets/whatsapp.png';

import { library } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-solid-svg-icons";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "far-fa" && key !== "prefix")
  .map((icon: any) => {
    return (Icons as any)[icon];
  });

library.add(...iconList);

class Rodape extends React.Component {
  render() {
    return (
      <div className="rodape">
        {/* <div className="icones-rodape"> */}
          {/* <FontAwesomeIcon icon="whatsapp-square"  size='1x' color='#fff' className="icone-tt" />
                    <FontAwesomeIcon icon="paper-plane"  size='1x' color='#fff' className="icone-tt" />
                    <FontAwesomeIcon icon="paper-plane"  size='1x' color='#fff' /> */}
          {/* <p className="text-rodape">Desenvolvido por IS Sistemas</p> */}
        {/* </div> */}
        <div className="contato-rodape">
          <p className="text-rodape">Contate-nos (51) 9 9931 9901</p>
          <a href="mailto:comercial@issistemas.com.br" className="email-rodape">
            comercial@issistemas.com.br
          </a>
          <br />
          Canoas/RS-Brasil
        </div>
      </div>
    );
  }
}

export default Rodape;
