import React from 'react';

import './header.css';

import logo from "../../assets/logo.png";

class Header extends React.Component{
    render(){
        return(
            <div className="cabecalho">
                <div className="line-blue">
                </div>
                <div className="container-logo">
                    <img src={logo} alt="Logo da empresa IS Sistemas" width="180px" height="60px"
                    className="logo-is"
                    />
                </div>
            </div>
        )
    }
}

export default Header;