import React from 'react';

import './app.css';

import Home from './components/home/Home';
import Intro from './components/intro/Intro';
import Declaration from './components/declaration/Declatarion';
import Testimonials from './components/testimonials/testimonials';
import Rodape from './components/rodape/Rodape';
import Header from './components/header/Header';

class App extends React.Component {
  render(){
    return (
      <div className="app">
        <Header />
        <Home />
        <Intro />
        <Declaration/>
        <Testimonials/>
        <Rodape />
      </div>
    );
  }
}
export default App;
  

