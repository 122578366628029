import React from "react";

import "./testimonials.css";

class Testimonials extends React.Component {
  render() {
    return (
      <div className="testimonials">
        <h1 className="titulo-negrito">Confira alguns cases das empresas que confiaram na IS Sistemas</h1>
        <div className="testimonials-cards">
          <div className="border-cards">
            <div className="fonte-is card-1">
              <h1 id="titulo-esq">Bracht Máquinas e Ferramentas</h1>
              <p className="desc-testimonials">
                Há 25 anos atuando no mercado gaúcho a Bracht se consolidou com umas das grandes do seu segmento, suprindo a necessidade do
                mercado metal mecânico com mais de 10.000 itens.
              </p>
            </div>
            <div className="img-banner img-banner-1"></div>
          </div>

          <div className="border-cards">
            <div className="fonte-is card-1">
              <h1 id="titulo-esq">A.H MAXI Dedetizações</h1>
              <p className="desc-testimonials">
                A.H. Maxi é uma empresa especializada no controle de Pragas Urbanas, fundada em 2001 em Canoas-RS. Percebendo uma grande
                demanda e um grande problema de entendimento de algumas pragas sem solução, trouxemos novas técnicas e produtos.
              </p>
            </div>

            <div className="img-banner img-banner-2"></div>
          </div>

          <div className="border-cards">
            <div className="fonte-is card-1">
              <h1 id="titulo-esq">Starseal Vedações</h1>
              <p className="desc-testimonials">
                A StarSeal Vedações, é uma empresa voltada a desenvolver soluções em vedações para atender as mais diversas aplicações e
                segmentos. Atua na fabricação, revenda e serviços de produtos para manutenção industrial de várias áreas como: alimentícia,
                metalúrgica, farmacêutica, bebidas, celulose, óleo e gás, automotiva, metal mecânica, fundição, construção civil,
                saneamento, movimentação de cargas, portuários, fundações, prestadores de serviços, revendas entre outras.
              </p>
            </div>
            <div className="img-banner img-banner-3"></div>
          </div>

          <div className="border-cards">
            <div className="fonte-is card-1">
              <h1 id="titulo-esq">Big Office Móveis Para Escritório</h1>
              <p className="desc-testimonials">
                A Big Office Móveis de Escritório está presente no mercado mobiliário desde 2006 e se destaca no fornecimento de linhas
                corporativas. Com uma equipe de profissionais especializados, atendimento diferenciado e suporte técnico, oferece dedicação
                exclusiva ao seu projeto.
              </p>
            </div>
            <div className="img-banner img-banner-4"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials;
