import React from "react";

import "../declaration/declaration.css";

class Declaration extends React.Component {
  render() {
    return (
      <div className="declaration">
        <div className="desc-texto">
          <h1 className="desc-titulo">
            Telhas Personalize sua Experiência no
            <br />
            IS Gestão.
          </h1>
          <div className="desc-declaration">
            Telhas é uma forma moderna de exibir dados de forma rápida e<br /> dinâmica.
          </div>
          <div className="desc-is-long">
            Ao entrar no sistema, o mesmo já lhe informa o que você precisa saber para iniciar seu trabalho. Contas a pagar ou receber no
            dia, saldo da conta ou caixa, tarefas agendadas ou atrasadas, quantidade ou total de vendas no mês, meta de vendas.
          </div>
        </div>
        <div className="img-note"></div>
      </div>
    );
  }
}

export default Declaration;
