import React from "react";

import "../intro/intro.css";
// import logo from "../../assets/logo.png";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "far" && key !== "prefix")
  .map((icon: any) => {
    return (Icons as any)[icon];
  });

library.add(...iconList);

class Intro extends React.Component {
  render() {
    return (
      <div className="intro">
        <div>
          <h1 className="titulo-central">Centralize tudo em único sistema.</h1>
        </div>

        <div className="intro-icones">
          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="money-check-alt" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">
                Mantenha as Contas a Pagar e <br /> Receber sempre atualizadas.
              </p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="chart-bar" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">Controle de Vendas</p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="newspaper" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">Nota Fiscal de Venda e Serviços</p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="envelope-open-text" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">Emita e Envie Boletos de Cobrança em Poucos Segundos</p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="object-group" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">
                Tenha Relatórios em <br /> Dashboards Personalizados{" "}
              </p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="check-circle" size="1x" color="#2A5699" />
            </div>

            <div>
              <p id="icone-text">
                Fature Automaticamente os <br /> Contratos
              </p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="clone" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">
                Tenhas as principais métricas <br /> resumida em telhas
              </p>
            </div>
          </div>

          <div className="box-icone">
            <div className="icone">
              <FontAwesomeIcon icon="file-signature" size="1x" color="#2A5699" />
            </div>
            <div>
              <p id="icone-text">
                Painéis que mostram métricas <br /> e indicadores importantes
              </p>
            </div>
          </div>
        </div>
        <div>
          <div>
            <h1 className="intro-titulo">Transforme suas planilhas eletrônicas em sistema.</h1>
          </div>
          <div className="fonte-is">
            <p className="text-center">
              Cadastro de Produto e Clientes com imagens;
              <br />
              Financeiro Completo com Fluxo de Caixa por Plano de Contas; <br />
              Módulo de Produção Simples;
              <br />
              Integração completa com sites OpenCart;
              <br />
              Módulo de Vendas em Android;
              <br />
              Sistema com relacionamento interno com acesso rápido de qualquer parte;
              <br />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
